.burger {
  display: none;
  padding: 1rem;
  width: 60px;
  height: 60px;
  background: transparent;

  & div {
    position: relative;
    top: 10px;
    width: 100%;
    height: 3px;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    z-index: 300;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: -10px;
      width: 100%;
      height: 3px;
      background: inherit;
    }

    &::after {
      top: 10px;
    }
  }

  &.is-active {
    &:hover > div {
      transform: rotate(225deg);
    }

    div {
      top: 10px;
      transform: rotate(135deg);

      &::before,
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }
  &__line {
    background-color: #000000;
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all linear 0.4s;
    width: 100%;
    &--top {
      top: 0;
    }
    &--middle {
      top: 10px;
    }
    &--bottom {
      top: 20px;
    }
  }

  @include desktop {
    display: block;
  }
}
