.intro {
  padding-bottom: 185px;
  &__row {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &__title {
    margin-top: 185px;
    color: #333;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  @include mobile {
    margin-top: 0;
  }

  &__subtitle {
    color: #222;
    font-family: Ubuntu;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;

    & span {
      padding: 5px;
      margin-top: 2rem;
      background: var(--main-color);
      color: #222;
      text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.42);
      font-family: Ubuntu;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  &__add {
    margin-top: 1rem;
  }

  &__present {
    margin-top: 50px;
    padding: 5px 10px;
    color: var(--black);
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    background-color: var(--main-color);
    border-radius: 5px;
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    gap: 1rem;
  }

  &__bg {
    position: absolute;
    left: 50%;
    top: 64%;
    transform: translate(-50%, -50%);
    width: 803px;
    height: 487px;
    flex-shrink: 0;
    // border-radius: 50%;
    // background: rgba(153, 153, 153, 0.7);
    // box-shadow: 0px 0px 40px 60px
    //  rgba(0, 0, 0, 0.25);
    box-shadow:
      27px 27px 55px #478e3e,
      -27px -27px 55px #61c054;
    z-index: -10;

    @include desktop {
      width: 100%;
      height: 110%;
      border-radius: 30px;
    }
  }
}

.card {
  backdrop-filter: blur(3px) saturate(184%);
  -webkit-backdrop-filter: blur(3px) saturate(184%);
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 50%;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
