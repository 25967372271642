.testimonials {
  padding-top: 6rem;
  padding-bottom: 10rem;
  background-color: var(--bg-blue);

  &__row {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: #000;
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 120% */
    letter-spacing: 1px;
  }

  &__box {
    position: relative;
    height: 100%;
  }

  &__name {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    left: 20px;
    bottom: 0;
  }
}
