.projects {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--bg-blue);

  &__row {
    margin-top: 3rem;

    & .swiper {
      padding-bottom: 3rem;
    }
  }
}
