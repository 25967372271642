.footer {
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__question {
    color: #000;
    font-family: Ubuntu;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  &__box {
    margin-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  &__tel-whatsapp {
    & img {
      width: 60px;
      height: 60px;
    }
  }

  &__tel-number {
    margin-top: 2rem;
    color: #000;
    font-family: Ubuntu;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
}
