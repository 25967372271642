.title {
  color: #000;
  font-family: Ubuntu;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;

  @include mobile {
    font-size: 24px;
  }

  & span {
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    font-family: Ubuntu;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background: var(--main-color);

    @include mobile {
      font-size: 24px;
    }
  }

  &-h3 {
    color: #000;
    text-align: center;
    font-family: Ubuntu;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}
