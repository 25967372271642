.garantee {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("../images/content/bg-garantee.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;

  &__title {
    color: var(--white);

    & span {
      background: var(--orange);
    }
  }

  &__text {
    color: #fff;
    font-family: Ubuntu;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__percent {
    color: #fff;
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__quality {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}
