.scheme {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__row {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 240px);
    justify-content: space-around;
    grid-template-rows: 240px 110px 240px;

    @include desktop {
      margin-top: 2rem;
      grid-template-columns: 768px;
      grid-template-rows: auto;
      gap: 2rem;
    }

    @include small-tablet {
      grid-template-columns: 420px;
    }

    @include mobile {
      grid-template-columns: 180px;
    }
  }

  &__img {
    position: absolute;

    & img {
      width: 1330px;
      height: 500px;
    }

    @include desktop {
      display: none;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & span {
      position: absolute;
      left: 30px;
      top: 65px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--orange);
      color: #000;

      /* card-title */
      font-family: Ubuntu;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    &1 {
      grid-column: 1 /2;
      grid-row: 1 / 3;

      @include desktop {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &2 {
      grid-column: 2 / 3;
      grid-row: 2 / 4;

      @include desktop {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &3 {
      grid-column: 3 / 4;
      grid-row: 1 / 3;

      @include desktop {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &4 {
      grid-column: 4 / 5;
      grid-row: 2 / 4;

      @include desktop {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &5 {
      grid-column: 5 / 6;
      grid-row: 1 / 3;

      @include desktop {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }

  &__text {
    margin-top: 1rem;
    color: #000;
    text-align: center;
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}
