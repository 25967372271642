.what {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--bg-blue);

  &__list {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 450px));
    align-items: center;
    justify-content: center;
    grid-gap: 30px;

    @include tablet {
      grid-template-columns: repeat(2, minmax(300px, 450px));
    }

    @include small-tablet {
      grid-template-columns: repeat(1, minmax(300px, 450px));
    }
  }

  &__item {
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.4s;

    &-title {
      height: 56px;
    }

    &:hover {
      transform: scale(1.05);
      background-color: var(--bg-color-alfa03);
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    }

    & img {
      margin-top: 37px;
      margin-bottom: 37px;
      border-radius: 0.5rem;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    & img {
      border-radius: 0;
    }

    & a {
      color: #000;
      text-align: center;
      font-family: Ubuntu;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;
      transition: all 0.4s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
