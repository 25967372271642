@mixin size($a, $b: $a) {
  width: $a;
  height: $b;
}

// Mixins of breakpoints

@mixin desktop {
  @media (max-width: (1248px)) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}

// functions

@function prc($pxOne, $pxTwo) {
  $result: math.div($pxOne, $pxTwo) * 100%;
  @return $result;
}

@function rem($px) {
  $result: math.div($px, 16) + rem;
  @return $result;
}

@function em($px) {
  $result: math.div($px, 16) + em;
  @return $result;
}

@mixin rotated-text($num-letters: 100, $angle-span: 180deg, $angle-offset: 0deg) {
  $angle-per-char: $angle-span / $num-letters;
  @for $i from 1 through $num-letters {
    .char#{$i} {
      @include transform(rotate($angle-offset + $angle-per-char * $i));
    }
  }
}
