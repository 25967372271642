@use "sass:math";
@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

body {
  height: 100%;
  font-family: "Ubuntu", sans-serif;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

*,
*::before,
*::after {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button {
  outline: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: $content-size;
  width: 100%;
}

.stop-scroll {
  overflow: hidden;
}

//Модули
@import "modules/modules";
