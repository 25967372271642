.order {
  padding-top: 3rem;
  padding-bottom: 4rem;
  background-image: url("../images/content/bg-order1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;

  &__title {
    color: var(--white);

    & span {
      background: var(--orange);
    }
  }

  &__text {
    margin-top: 2rem;
    color: #fff;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    text-align: center;
  }

  &__row {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 250px);
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include tablet {
      grid-template-columns: repeat(1, 250px);
    }
  }

  &__form {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__btn {
    padding: 1rem 2rem;
    background-color: var(--white);
    border-radius: 1rem;

    &--whatsapp {
      color: #6cc72a;

      /* whats */
      font-family: Ubuntu;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &--telegram {
      color: #2aabee;

      /* whats */
      font-family: Ubuntu;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__input {
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    color: rgba(0, 0, 0, 0.8);

    /* whats */
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 1rem;
    border: none;
    outline: none;
  }
}
