.header {
  width: 100%;
  height: 998px;
  padding-top: 185px;
  padding-bottom: 185px;
  background-image: url("../images/general/bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;

  &__container {
    position: fixed;
    right: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 1410px;
    z-index: 500;
  }

  &__navbar {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--bg-color-alfa03);
    transition: all 0.4s;

    &.bg-alfa1 {
      background-color: var(--bg-color-alfa1);
    }
  }

  &__tel {
    &-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    &-number {
      margin-top: 16px;
      text-align: center;
      font-weight: 700;
    }

    &-phone {
      width: 32px;
      height: 32px;
      // background: var(--main-color);
    }
  }

  &__whatsapp {
    background: var(--white);
    border-radius: 50%;
  }
}

.logo {
  & img {
    background-color: transparent;
  }

  @include mobile {
    display: none;
  }
}
