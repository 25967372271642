// Colors

:root {
  --white: #ffffff;
  --black: #000000;
  --main-color: #6cc72a;
  --bg-color-alfa03: rgba(107, 241, 120, 0.3);
  --bg-color-alfa1: rgba(107, 241, 120, 1);
  --bg-color: #6bf178;
  --bg-blue: #e2f1ff;
  --orange: #ffe327;
}

// Sizes
$base-font-size: 16px;

$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px
);

$content-size: 1440px;

// Fonts
