.swiper {
  width: 1170px;

  @include desktop {
    width: 1170px;
  }

  @include tablet {
    width: 520px;
  }

  @include mobile {
    width: 180px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &2 {
    width: 700px;
    height: 500px;

    @include tablet {
      width: 500px;
      height: 500px;
    }

    @include mobile {
      width: 200px;
      height: 700px;
    }
  }
}

.projects__row > .swiper-button-prev {
  left: 5rem;

  @include desktop {
    left: 0;
  }
}

.projects__row > .swiper-button-next {
  right: 5rem;

  @include desktop {
    right: 0;
  }
}
