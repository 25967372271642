.menu {
  @include desktop {
    position: fixed;
    top: var(--header-height);
    right: 0;
    max-width: 330px;
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--main-color);
    border-radius: 15px;
    transform: translateX(200%);
    transition: all 0.4s;
    z-index: 1000;

    &.open {
      transform: none;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;

    @include desktop {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
  }

  &__item {
    cursor: pointer;
    transition: all 0.4s;

    @include desktop {
      padding: 10px 20px;
    }

    & a {
      padding: 10px 20px;
      position: relative;
      color: var(--black);
      font-family: Ubuntu;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      @include desktop {
        font-size: 24px;
      }

      &:hover {
        background: var(--bg-color);
        border-radius: 10px;
      }

      &:hover::after {
        width: 100%;
      }
    }

    & a::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 0;
      height: 2px;
      background-color: var(--main-color);
      transition: width 0.4s ease-out;
    }
  }
}
