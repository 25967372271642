.why {
  padding-top: 4rem;
  padding-bottom: 4rem;
  &__row {
    margin-top: 2rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2rem;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__icon {
    min-width: 25px;
    min-height: 25px;
  }

  &__text {
    color: #000;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;

    & span {
      color: #000;
      font-family: Ubuntu;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}
