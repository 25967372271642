.btn {
  padding: 19px 28px;
  background: var(--main-color);
  color: var(--black);
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 10px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.4s ease-out;

  &:hover {
    transform: scale(1.05);
    background: var(--bg-color);
  }
}
